<template>
    <div>
      <span ref="demo">ref 例子</span>
      <button @click="handleClick">点击</button >
      <hr/>
      <Son ref="son"/>
    </div>
</template>

<script>
import Son from "./son.vue";
    export default {
        components:{
            Son,
        },
       methods:{
           handleClick(){
               console.log(this.$refs.demo.innerText);
               console.log(this.$refs.son.msg);
           }
       }
    }
</script>

<style lang="scss" scoped>

</style>